
import Swup from '/node_modules/swup';
import SwupMorphPlugin from '/node_modules/swup-morph-plugin';
// import {renderDate} from "{{ '/assets/js/main.js' | url }}"
// {# import {renderDate} from "main" #}

// We use SwupMorphPlugin for updating the nav 
// (Allows morphing containers into the new page without replacing or animating them.)
const config = {
    containers: ['#main'],
    plugins: [new SwupMorphPlugin({
      containers: ['#nav']
    })]
}

const swup = new Swup(config);

renderDate()

swup.hooks.on('page:view', (visit) => {
  renderDate()
})

/*
        let date = relativeDate(new Date("{{ page.date }}"));
        document.getElementById("date-caption").innerHTML = date;
        swup.hooks.on('page:view', (visit) => {
          let date = relativeDate(new Date("{{ page.date }}"));
          document.getElementById("date-caption").innerHTML = date;
        })
*/

export function relativeDate(date) {
    const diff = Math.round((new Date() - new Date(date)) / 1000);

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30;
    const year = month * 12;

    if (diff < 30) {
      return "just now...";
    } else if (diff < minute) {
      return diff + " seconds ago...";
    } else if (diff < 2 * minute) {
      return "a minute ago...";
    } else if (diff < hour) {
      return Math.floor(diff / minute) + " minutes ago...";
    } else if (Math.floor(diff / hour) == 1) {
      return "1 hour ago...";
    } else if (diff < day) {
      return Math.floor(diff / hour) + " hours ago...";
    } else if (diff < day * 2) {
      return "yesterday";
    } else if (diff < week) {
      return Math.floor(diff / day) + " days ago...";
    } else if (diff < month) {
      return Math.floor(diff / week) + " weeks ago...";
    } else if (diff < year) {
      return Math.floor(diff / month) + " months ago...";
    } else {
      return Math.floor(diff / year) + " years ago...";
    }
  }

  export function renderDate()
  {
    const dateCaption = document.querySelector("#date-caption")
    let date = relativeDate(new Date(dateCaption.dataset.date))
    document.getElementById("date-caption").innerHTML = date
  }